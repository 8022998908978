<template>
  <div class="navigation">
    <div class="navigation__menu">
      <el-menu @select="setRoute" v-if="isUserRoleSuperAdministrator">
        <el-menu-item index="AdministrationSuperAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label">
              Radna ploča
            </span>
          </template>
        </el-menu-item>
        <el-sub-menu index="AdministrationSuperAdministratorShopIndex">
          <template #title>
            <span class="icon">
              <ShoppingBag />
            </span>
            <span class="label">Shop</span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorShopOrdersIndex">
            <template #title>
            <span class="label">
              Narudžbe
            </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorShopShopsIndex">
            <template #title>
            <span class="label">
              Proizvođači
            </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorShopCouponsIndex">
            <template #title>
              <span class="label">
                Kuponi
              </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="AdministrationSuperAdministratorSettingsIndex">
          <template #title>
            <span class="icon">
              <Setting />
            </span>
            <span class="label">
              Postavke
            </span>
          </template>
          <el-menu-item index="AdministrationSuperAdministratorSettingsCurrenciesIndex">
            <template #title>
              <span class="label">
                Valute
              </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationSuperAdministratorSettingsProductCategoriesIndex">
            <template #title>
              <span class="label">
                Kategorije proizvoda
              </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="AdministrationSuperAdministratorUsersIndex">
          <template #title>
            <span class="icon">
              <User />
            </span>
            <span class="label">
              Korisnici
            </span>
          </template>
        </el-menu-item>
      </el-menu>
      <el-menu @select="setRoute" v-if="isUserRoleAdministrator">
        <el-menu-item index="AdministrationAdministratorDashboardIndex">
          <template #title>
            <span class="icon">
              <House />
            </span>
            <span class="label">
              Radna ploča
            </span>
          </template>
        </el-menu-item>
        <el-sub-menu index="AdministrationAdministratorShopIndex">
          <template #title>
            <span class="icon">
              <ShoppingBag />
            </span>
            <span class="label">Shop</span>
          </template>
          <el-menu-item index="AdministrationAdministratorShopOrdersIndex">
            <template #title>
            <span class="label">
              Narudžbe
            </span>
            </template>
          </el-menu-item>
          <el-menu-item index="AdministrationAdministratorShopShopsIndex">
            <template #title>
            <span class="label">
              Proizvođači
            </span>
            </template>
          </el-menu-item>
        </el-sub-menu>
      </el-menu>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { Options, Vue } from "vue-class-component";

// Store
import store from "@/store";

// Components
import { ElMenu, ElMenuItemGroup, ElMenuItem, ElSubMenu } from "element-plus";

import {
  House,
  ShoppingBag,
  Setting,
  User,
} from "@element-plus/icons-vue";

@Options({
  components: {
    ElMenu,
    ElMenuItemGroup,
    ElMenuItem,
    ElSubMenu,
    House,
    ShoppingBag,
    Setting,
    User,
  },
})
export default class Navigation extends Vue {
  get isUserRoleSuperAdministrator(): boolean {
    return store.getters.getUser.role === "super-administrator";
  }

  get isUserRoleAdministrator(): boolean {
    return store.getters.getUser.role === "administrator";
  }

  setRoute(routeName: string): void {
    this.$router.push({
      name: routeName,
    });
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/core/_variables.scss";
@import "~@/assets/scss/mixins/_breakpoints.scss";

.navigation {
  width: 200px;
  min-width: 200px;
  min-height: calc(100vh - 134px);
  padding: 24px;
  border-right: 1px solid $grey-60;

  // Mobile
  @include media-breakpoint-down(md) {
    width: 100%;
    min-width: unset;
    min-height: unset;
    padding: 0;
    border-right: 0;
    border-bottom: 1px solid $grey-60;
  }

  &__menu {
    // Mobile
    //@include media-breakpoint-down(md) {
    //  overflow-x: auto;
    //  overflow-y: scroll;
    //  white-space: nowrap;
    //  scrollbar-width: none;
    //
    //  &::-webkit-scrollbar {
    //    display: none;
    //  }
    //}

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      // Mobile
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        text-align: left;
      }

      li {
        padding-top: 4px;
        padding-bottom: 4px;

        // Mobile
        @include media-breakpoint-down(md) {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    :deep(.el-menu) {
      background-color: transparent;
      border-right: 0;
    }

    .el-sub-menu {
      .icon {
        position: relative;
        top: -3px;
        width: 20px;
        margin-right: 8px;

        // Mobile
        @include media-breakpoint-down(md) {
          top: -2px;
          width: 16px;
          margin-right: 4px;
        }
      }

      .label {
        font-size: 12px;
        font-weight: 600;
      }

      * {
        vertical-align: middle;
      }

      .el-menu {
        .el-menu-item {
          margin-left: 8px;

          // Mobile
          @include media-breakpoint-down(md) {
            margin-left: 0;
          }
        }
      }
    }

    .el-menu-item {
      .icon {
        position: relative;
        top: -3px;
        width: 20px;
        margin-right: 8px;

        // Mobile
        @include media-breakpoint-down(md) {
          top: -2px;
          width: 16px;
          margin-right: 4px;
        }
      }

      .label {
        font-size: 12px;
        font-weight: 600;
      }

      * {
        vertical-align: middle;
      }

      &.is-active {
        color: $primary;
      }
    }
  }
}
</style>
