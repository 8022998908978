// Vendors
import axios from "axios";

// Store
import store from "@/store";

// Router
import router from "@/router";

// Services
import { getStatus, setNotification } from "@/services/app/common";

const Api = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});

Api.interceptors.response.use(
  (r: any) => {
    return r;
  },
  async (e: any) => {
    if (e.response.status === 401 && store.getters.getAuthentication.token !== "") {
      setNotification("Token je istekao", "warning");
      await store.dispatch("setLogout");
      await router.push({ name: "HomeIndex" });
    }

    if (e.response.status === 403 && store.getters.getAuthentication.token !== "") {
      setNotification("Nije dozvoljen pristup podacima", "warning");
      await store.dispatch("setLogout");
      await router.push({ name: "HomeIndex" });
    }

    return Promise.reject(e);
  }
);

export default Api;
