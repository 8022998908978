import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_el_config_provider = _resolveComponent("el-config-provider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_config_provider, { locale: _ctx.locale }, {
      default: _withCtx(() => [
        (_ctx.isLoggedIn)
          ? (_openBlock(), _createBlock(_component_Header, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass(["wrapper", { 'wrapper--is-app': _ctx.isLoggedIn }])
        }, [
          (_ctx.isLoggedIn)
            ? (_openBlock(), _createBlock(_component_Navigation, { key: 0 }))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view)
        ], 2)
      ]),
      _: 1
    }, 8, ["locale"])
  ]))
}