// Router
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";

// Store
import store from "@/store/index";

const routerRedirectToDashboard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  switch (store.getters.getUser.role) {
    case "super-administrator":
      next({ name: "AdministrationSuperAdministratorDashboardIndex" });
      break;
    case "administrator":
      next({ name: "AdministrationAdministratorDashboardIndex" });
      break;
    default:
      store.dispatch("setLogout").then((): void => {
        next({ name: "HomeIndex" });
      });
  }
};

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    next();
  } else if (to.meta.protectedRoute && store.getters.getAuthentication.token === "") {
    next({ name: "HomeIndex" });
  } else if (!to.meta.protectedRoute && store.getters.getAuthentication.token !== "") {
    routerRedirectToDashboard(to, from, next);
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "home-index" */ "../views/home/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-login-index" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-index" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery/change-password/:token",
    name: "AuthenticationPasswordRecoveryChangePasswordIndex",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-change-password-index" */ "../views/authentication/password-recovery/change-password/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/dashboard",
    name: "AdministrationSuperAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-dashboard-index" */ "../views/administration/super-administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/users",
    name: "AdministrationSuperAdministratorUsersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-users-index" */ "../views/administration/super-administrator/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/orders",
    name: "AdministrationSuperAdministratorShopOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-orders-index" */ "../views/administration/super-administrator/shop/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/orders/:order_id/view",
    name: "AdministrationSuperAdministratorShopOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-orders-view-index" */ "../views/administration/super-administrator/shop/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops",
    name: "AdministrationSuperAdministratorShopShopsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-index" */ "../views/administration/super-administrator/shop/shops/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/view",
    name: "AdministrationSuperAdministratorShopShopsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-view-index" */ "../views/administration/super-administrator/shop/shops/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/products",
    name: "AdministrationSuperAdministratorShopShopsProductsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-products-index" */ "../views/administration/super-administrator/shop/shops/products/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/products/:product_id/view",
    name: "AdministrationSuperAdministratorShopShopsProductsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shops-shop-products-view-index" */ "../views/administration/super-administrator/shop/shops/products/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/orders",
    name: "AdministrationSuperAdministratorShopShopsOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-orders-index" */ "../views/administration/super-administrator/shop/shops/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/orders/:order_id/view",
    name: "AdministrationSuperAdministratorShopShopsOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shops-shop-orders-view-index" */ "../views/administration/super-administrator/shop/shops/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/shop-photos",
    name: "AdministrationSuperAdministratorShopShopsShopPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-shop-photos-index" */ "../views/administration/super-administrator/shop/shops/shop-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/coupons",
    name: "AdministrationSuperAdministratorShopShopsCouponsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-coupons-index" */ "../views/administration/super-administrator/shop/shops/coupons/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/shop-delivery-settings",
    name: "AdministrationSuperAdministratorShopShopsShopDeliverySettingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-shop-delivery-settings-index" */ "../views/administration/super-administrator/shop/shops/shop-delivery-settings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/shop-locations",
    name: "AdministrationSuperAdministratorShopShopsShopLocationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-shop-locations-index" */ "../views/administration/super-administrator/shop/shops/shop-locations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/shop-subscriptions",
    name: "AdministrationSuperAdministratorShopShopsShopSubscriptionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-shop-subscriptions-index" */ "../views/administration/super-administrator/shop/shops/shop-subscriptions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/shop-owners",
    name: "AdministrationSuperAdministratorShopShopsShopOwnersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-shop-owners-index" */ "../views/administration/super-administrator/shop/shops/shop-owners/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/shops/:shop_id/shop-payment-types",
    name: "AdministrationSuperAdministratorShopShopsShopPaymentTypesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-shops-shop-payment-types-index" */ "../views/administration/super-administrator/shop/shops/shop-payment-types/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/shop/coupons",
    name: "AdministrationSuperAdministratorShopCouponsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-shop-coupons-index" */ "../views/administration/super-administrator/shop/coupons/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/currencies",
    name: "AdministrationSuperAdministratorSettingsCurrenciesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-currencies-index" */ "../views/administration/super-administrator/settings/currencies/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/super-administrator/settings/product-categories",
    name: "AdministrationSuperAdministratorSettingsProductCategoriesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-super-administrator-settings-product-categories-index" */ "../views/administration/super-administrator/settings/product-categories/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/dashboard",
    name: "AdministrationAdministratorDashboardIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-dashboard-index" */ "../views/administration/administrator/dashboard/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/orders",
    name: "AdministrationAdministratorShopOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-orders-index" */ "../views/administration/administrator/shop/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/orders/:order_id/view",
    name: "AdministrationAdministratorShopOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-orders-view-index" */ "../views/administration/administrator/shop/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops",
    name: "AdministrationAdministratorShopShopsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-index" */ "../views/administration/administrator/shop/shops/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/view",
    name: "AdministrationAdministratorShopShopsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-view-index" */ "../views/administration/administrator/shop/shops/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/products",
    name: "AdministrationAdministratorShopShopsProductsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-products-index" */ "../views/administration/administrator/shop/shops/products/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/products/:product_id/view",
    name: "AdministrationAdministratorShopShopsProductsViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shops-shop-products-view-index" */ "../views/administration/administrator/shop/shops/products/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/orders",
    name: "AdministrationAdministratorShopShopsOrdersIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-orders-index" */ "../views/administration/administrator/shop/shops/orders/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/orders/:order_id/view",
    name: "AdministrationAdministratorShopShopsOrdersViewIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shops-shop-orders-view-index" */ "../views/administration/administrator/shop/shops/orders/view/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/shop-photos",
    name: "AdministrationAdministratorShopShopsShopPhotosIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-shop-photos-index" */ "../views/administration/administrator/shop/shops/shop-photos/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/coupons",
    name: "AdministrationAdministratorShopShopsCouponsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-coupons-index" */ "../views/administration/administrator/shop/shops/coupons/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/shop-delivery-settings",
    name: "AdministrationAdministratorShopShopsShopDeliverySettingsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-shop-delivery-settings-index" */ "../views/administration/administrator/shop/shops/shop-delivery-settings/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/shop-locations",
    name: "AdministrationAdministratorShopShopsShopLocationsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-shop-locations-index" */ "../views/administration/administrator/shop/shops/shop-locations/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/shop-subscriptions",
    name: "AdministrationAdministratorShopShopsShopSubscriptionsIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-shop-subscriptions-index" */ "../views/administration/administrator/shop/shops/shop-subscriptions/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/administration/administrator/shop/shops/:shop_id/shop-payment-types",
    name: "AdministrationAdministratorShopShopsShopPaymentTypesIndex",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "administration-administrator-shop-shops-shop-payment-types-index" */ "../views/administration/administrator/shop/shops/shop-payment-types/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
